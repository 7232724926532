
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

@Component
export default class PropertyFormRadio extends Vue {
    @Prop() value!: string | boolean | number;
    @Prop() radioValue!: string | boolean;
    @Prop() name!: string;
    @Prop({ default: false }) disabled?: boolean;
    @Prop({ default: false }) hasAmounts?: boolean;

    localValue = "";

    get isChecked() {
        if (Array.isArray(this.value)) {
            return this.value.includes(this.radioValue);
        }

        if (this.hasAmounts) {
            return this.value !== undefined && this.value !== false && this.value !== null && this.value > 0;
        }

        return this.value === this.radioValue;
    }

    handleDecrement() {
        if (Array.isArray(this.value) || !this.hasAmounts) {
            this.handleClick();
            return;
        }

        if (this.value === undefined || this.value === false || this.value === null) {
            this.$emit("input", 1);
            return;
        }

        if (this.value === 1) {
            this.$emit("input", undefined);
            return;
        }

        this.$emit("input", Number.parseInt(this.value + "") - 1);
    }

    handleIncrement() {
        if (Array.isArray(this.value) || !this.hasAmounts) {
            this.handleClick();
            return;
        }

        if (this.value === undefined || this.value === false || this.value === null) {
            this.$emit("input", 1);
            return;
        }
        this.$emit("input", Number.parseInt(this.value + "") + 1);
    }

    handleClick() {
        if (this.isChecked && !Array.isArray(this.value)) {
            this.$emit("input", undefined);
        } else {
            this.$emit("input", this.radioValue);
        }
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
        this.localValue = newValue;
    }
}
